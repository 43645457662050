import { createApp } from "vue";

import { router } from "@/router/router";

import "@/assets/styles/main.css";

import App from "./App.vue";

export const app = createApp(App);

app.use(router);

router.isReady().then(() => app.mount("#app"));
