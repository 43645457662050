import { createRouter, createWebHistory } from "vue-router";

import Home from "@/pages/Home.vue";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },

  // Not yet decided whether to keep this
  {
    name: "home",
    path: "/",
    component: Home,
  },

  {
    name: "profile",
    path: "/:profile",
    component: Home,
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
