<template>
  <div class="content-wrapper" data-afp-number="0">
    <div class="user-page-section theme-class-10">
      <div class="user-page-section-background lozad"></div>
      <div class="container">
        <div
          class="wrapper avatar-page-layout"
          id="userWrapper"
          data-visitor_country="UA"
          data-clickurl="https://click.heylink.me"
          data-userid="3795631"
        >
          <div class="user-page-section-content">
            <div class="user-info">
              <div v-if="profile" class="logo-container">
                <div class="common-user-icon__component avatar-style-01">
                  <div
                    class="lozad common-user-icon-wrapper"
                    :style="{
                      'background-image':
                        'url(' + profile.profileImageUrl + ')',
                    }"
                  ></div>
                </div>
              </div>
              <div v-if="profile" class="username center" style>
                {{ profile.profileName }}
              </div>
              <div v-else class="username center" style>No profile found</div>
            </div>
            <div v-if="profile" class="preview-links-list__component">
              <div
                v-for="(link, index) in profile.links"
                :key="index"
                class="preview-link-item__component theme-class-10"
              >
                <a
                  :href="link.url"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="preview-link-wrapper"
                >
                  <div class="link-info left" data-id="6116671">
                    <div class="name" style="">{{ link.label }}</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";

import config from "@/config.json";

export default {
  name: "Home",
  components: {},

  setup() {
    const route = useRoute();

    const profile = computed(() => {
      const profileKey = route.params?.profile;

      return config[profileKey] || null;
    });

    onMounted(() => {
      document.title = profile.value?.profileName || "My profile links";
    });

    return {
      profile,
    };
  },
};
</script>

<style></style>
